import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../../model/login/user.model';
import { Observable } from 'rxjs/index';
import { ApiResponse } from '../../model/login/api.response';
import { Globals } from '../../model/globals.model';
import { InformacionUsuarios } from '../../model/administracion/usuarios.model';
import { JwtHelperService } from '@auth0/angular-jwt/src/jwthelper.service';

const jwtHelper = new JwtHelperService();


@Injectable({providedIn: "root"})
export class ApiService {
  username: any;

  constructor( private globals: Globals, private http: HttpClient) {
    this.username = window.localStorage.getItem('username');
  }

  baseUrlServidor = this.globals.dominioApiService + 'users/';
  baseUrlLocal = this.globals.dominioApiService + 'users/';
  baseUrlLocalPorEquipo = this.globals.dominioApiService + 'users/buscarUsuariosPorEquipoId/';
  baseUrlLocalPorEquipoSinPaginado = this.globals.dominioApiService + 'equiposUsuarios/buscarEquiposUsuariosIdEquipoSinPaginado/';
  baseUrlLocalListarUsuariosEstado = this.globals.dominioApiService + 'users/listarUsuariosPorEstado/';
  baseUrlCambiarContraseña = this.globals.dominioApiService + 'users/updatePassword/';
  baseUrlListarRoles = this.globals.dominioApiService + 'roles/';
  baseUrlListarParents = this.globals.dominioApiService + 'users/listarParentsId';
  baseUrlGuardarPersonal = this.globals.dominioApiService + 'personas/'


  baseUrlGuardarPersonalAdmin = this.globals.dominioApiService + 'personas/'


  login(loginPayload): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.globals.dominioApiService + '' + 'token/generate-token', loginPayload);
  }

  public isUserAuthenticated(): boolean {
    ;
    const accessToken = localStorage.getItem('token');
    return !jwtHelper.isTokenExpired(accessToken);
  }

  listarUser(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlLocal);
  }
  listarUsersEquipo(idEquipo): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlLocalPorEquipoSinPaginado + idEquipo);
  }
  listarUsersEquipoPaginado(idEquipo,params): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlLocalPorEquipo + idEquipo,{params});
  }
  listarUserPorEstado(estado): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlLocalListarUsuariosEstado + estado);
  }

  getUserById(id: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlLocal + id);
  }

  createUser(user: InformacionUsuarios): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlLocal, user);
  }
  guardarPersonalConPermisos(Modelo): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlGuardarPersonal, Modelo);
  }
  actualizarUsuarioPermisos(Modelo): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlGuardarPersonalAdmin, Modelo);
  }
  guardarPermisosTipoCuentasAdmin(Modelo): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlGuardarPersonalAdmin, Modelo);
  }
  getRolById(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlLocal + id);
  }

  updateUser(user: InformacionUsuarios): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(this.baseUrlLocal, user);
  }
  updatePassword(user: InformacionUsuarios, contraAnterior, idRol): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(this.baseUrlCambiarContraseña + contraAnterior + '/' + idRol, user);
  }

  deleteUser(id: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.baseUrlLocal + id);
  }

  listarRoles(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlListarRoles);
  }
  listarParent(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlListarParents);
  }

}
