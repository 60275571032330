import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'src/core/guard';

export const AppRoutes: Routes = [
  { path: "", loadChildren: () => import('./layouts/admin-layout.module').then(m => m.AdminLayoutModule) },
  {
    path: "login",
    loadChildren: () => import('../login/login.module').then(m => m.LoginModule),
    canActivate: [AuthGuard],
  },
  { path: "**", redirectTo: "not-found" },
  /*   {
      path: '',
      redirectTo: 'login',
      pathMatch: 'full',
    }, { */
  /*     path: '', */
  /* component: LoginComponent, */
  /*   children: [
      {
        path: '',
        loadChildren: './login/login.module#LoginModule'
      }]
  },
  { path: '', loadChildren: './layouts/admin-layout/layout.module#LayoutModule' },
 
 
  {
    path: '**',
    redirectTo: 'login'
  } */
]
@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
