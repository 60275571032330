import { BeanCombinados } from "./../../model/cuentas/cuentas.model";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs/index";
import { ApiResponse } from "../../model/login/api.response";
import { map } from "rxjs/operators";
import { Globals } from "../../model/globals.model";

@Injectable()
export class VentasService {
  constructor(private globals: Globals, private http: HttpClient) {}
  baseUrlGenerarVenta =
    this.globals.dominioApiService + "cuentas/venderCuentasPorPantalla/";
  baseUrlGenerarVentaCuenta =
    this.globals.dominioApiService + "cuentas/venderCuentasUnica/";
  baseUrlListarVentaPorAdmin =
    this.globals.dominioApiService + "ventasUsuario/";
  baseUrlListarVentaPorUsuario =
    this.globals.dominioApiService + "ventasUsuario/reporteVentasPorUsuario/";
  baseUrlListarVentasPorEquipo =
    this.globals.dominioApiService + "ventasUsuario/reporteVentasPorEquipo/";
  baseUrlListarVentasPorFechaUsuario =
    this.globals.dominioApiService +
    "ventasUsuario/reporteVentasPorFechasUsuario/";
  baseUrlListarVentasPorFechaEquipo =
    this.globals.dominioApiService +
    "ventasUsuario/reporteVentasPorFechasEquipo/";
  baseUrlListarVentasPorFechaAdmin =
    this.globals.dominioApiService +
    "ventasUsuario/reporteVentasPorFechasAdmin/";
  baseUrlEnviarMensaje =
    this.globals.dominioApiService + "cuentas/enviarMensaje/";

  venderPantalla(
    idTipo,
    idUsuario,
    idEquipo,
    idRol,
    nombreCliente,
    numeroCelular
  ): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlGenerarVenta +
        idTipo +
        "/" +
        idUsuario +
        "/" +
        idEquipo +
        "/" +
        idRol +
        "/" +
        nombreCliente +
        "/" +
        numeroCelular 
    );
  }
  venderCuenta(
    idTipo,
    idUsuario,
    idEquipo,
    idRol,
    nombreCliente,
    numeroCelular
    
  ): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlGenerarVentaCuenta +
        idTipo +
        "/" +
        idUsuario +
        "/" +
        idEquipo +
        "/" +
        idRol +
        "/" +
        nombreCliente +
        "/" +
        numeroCelular 
    );
  }
  listarVentasPorUsuario(idUsuario): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlListarVentaPorUsuario + idUsuario
    );
  }
  listarVentasPorUsuarioPaginado(idUsuario,params): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlListarVentaPorUsuario + idUsuario,{params}
    );
  }
  listarVentasPorAdmin(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlListarVentaPorAdmin);
  }
  listarVentasPorAdminPaginado(params): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlListarVentaPorAdmin,{params});
  }
  listarVentasPorEquipo(idEquipo): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlListarVentasPorEquipo + idEquipo
    );
  }
  listarVentasPorEquipoPaginado(idEquipo,params): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlListarVentasPorEquipo + idEquipo, {params}
    );
  }
  listarVentasUsuarioPorFechas(
    idUsuario,
    BeanCombinados
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.baseUrlListarVentasPorFechaUsuario + idUsuario,
      BeanCombinados
    );
  }
  listarVentasEquiposPorFechas(
    idUser,
    beanCombinados
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.baseUrlListarVentasPorFechaEquipo + idUser,
      beanCombinados
    );
  }
  listarVentasAdminPorFechas(
    idEquipo,
    beanCombinados
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.baseUrlListarVentasPorFechaAdmin,
      beanCombinados
    );
  }
  enviarMensaje(beanMensaje): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlEnviarMensaje, beanMensaje);
  }
}
