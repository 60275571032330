import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs/index";
import { ApiResponse } from "../../model/login/api.response";
import { map } from "rxjs/operators";
import { Globals } from "../../model/globals.model";

@Injectable()
export class PrecioCategoriaServices {
  constructor(private globals: Globals, private http: HttpClient) {}

  baseUrlPreciosIdCategoria =
    this.globals.dominioApiService + "preciosCategoria/preciosPorIdCategoria/";
  baseUrlPreciosPermisos =
    this.globals.dominioApiService +
    "preciosCategoria/insertarPermisosPrecios/";
  baseUrlActualizarPreciosPermisos =
    this.globals.dominioApiService + "preciosCategoria/actualizarPrecioFinal/";
  baseUrlActualizarPreciosDist =
    this.globals.dominioApiService + "preciosCategoria/actualizarPrecioDist/";

  baseUrlGuardarPreciosDist =
    this.globals.dominioApiService + "preciosCategoria/actualizarPrecioDist/";

    baseUrlGuardarPreciosCategorias =
    this.globals.dominioApiService + "preciosCategoria/guardarPrecioCategorias/";

  consutarPreciosIdCategoria(idCategoria): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlPreciosIdCategoria + idCategoria
    );
  }

  mapearPreciosTipoCuenta(idUsuario, idEquipo): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlPreciosPermisos + idUsuario + "/" + idEquipo
    );
  }

  actualizarPrecioCuenta(idPrecio, valor): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlActualizarPreciosPermisos + idPrecio + "/" + valor
    );
  }

  actualizarPrecioCuentaDist(idTipoCuenta, valor): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlActualizarPreciosDist + idTipoCuenta + "/" + valor
    );
  }

  guardarPreciosCategorias(preciosCuenta,idCategoria): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlGuardarPreciosCategorias + idCategoria, preciosCuenta);
  }
}
