import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/index';
import { ApiResponse } from '../../model/login/api.response';
import { Globals, ResponseFull } from '../../model/globals.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';
import { RolesInterface } from '../../model/roles/roles.model';

const jwtHelper = new JwtHelperService();

@Injectable()
export class PermisosService {
    permisosDeRol: any;

    constructor( private globals: Globals, private http: HttpClient) {
        this.permisosDeRol = globals.permisosDeRol;
    }

    baseUrlListaRoles = this.globals.dominioApiService + 'roles/';
    baseUrlObtenerPermisosPorRolDeUsuario = this.globals.dominioApiService + 'permisosrolsistema/';
    baseUrlListaModulosDelSistema = this.globals.dominioApiService + 'modulossistema/';
    baseUrlListaFuncionalidadesPorModulosDelSistema = this.globals.dominioApiService + 'modulossistema/funcionalidades';
    baseUrlListaFuncionalidadesDelSistema = this.globals.dominioApiService + 'funcionalidadessistema/';
    baseUrlActualizarEstadoDePermiso = this.globals.dominioApiService + 'permisosrolsistema/actualizarEstadoDePermiso/';
    baseUrlActualizarEstadoDeRol = this.globals.dominioApiService + 'roles/actualizarEstadoDeRol/';
    baseUrlGuardarRol = this.globals.dominioApiService + 'roles/';


    getListaRolesDelSistema(): Observable<ResponseFull> {
        return this.http.get<ResponseFull>(this.baseUrlListaRoles);
    }

    getListaFuncionalidadesPorModulosDelSistema(): Observable<ResponseFull> {
        return this.http.get<ResponseFull>(this.baseUrlListaFuncionalidadesPorModulosDelSistema);
    }

    getListaModulosDelSistema(): Observable<ResponseFull> {
        return this.http.get<ResponseFull>(this.baseUrlListaModulosDelSistema);
    }

    getListaFuncionalidadesDelSistema(): Observable<ResponseFull> {
        return this.http.get<ResponseFull>(this.baseUrlListaFuncionalidadesDelSistema);
    }

    getListaFuncionalidadesDelSistemaPorModuloId(moduloId): Observable<ResponseFull> {
        return this.http.get<ResponseFull>(this.baseUrlListaFuncionalidadesDelSistema + moduloId);
    }

    obtenerPermisosPorRolDeUsuarioAsync(idRol: any, estadoRol: any, estadoPermiso: any): Observable<ResponseFull> {
        return this.http.get<ResponseFull>(this.baseUrlObtenerPermisosPorRolDeUsuario + idRol + '/' + estadoRol + '/' + estadoPermiso);
    }

    postActualizarEstadoPermisoPorRolId(idRol: number, idFuncionalidad: number, estadoPermiso: string): Observable<ResponseFull> {
        return this.http.post<ResponseFull>(this.baseUrlActualizarEstadoDePermiso + idRol + '/' + idFuncionalidad + '/' + estadoPermiso, null).pipe(map(data => data));
    }

    postActualizarEstadoRolId(idRol: number, estadoRol: string): Observable<ResponseFull> {
        return this.http.put<ResponseFull>(this.baseUrlActualizarEstadoDeRol + idRol + '/' + estadoRol, null).pipe(map(data => data));
    }

    postGuardarRol(rolInterface: RolesInterface): Observable<ResponseFull> {
        return this.http.post<ResponseFull>(this.baseUrlGuardarRol, rolInterface).pipe(map(data => data));
    }

    async obtenerPermisosPorRolDeUsuario(idRol: any, estadoRol: any, estadoPermiso: any): Promise<any> {
        try {
            await this.http.get<ApiResponse>(this.baseUrlObtenerPermisosPorRolDeUsuario + idRol + '/' + estadoRol + '/' + estadoPermiso).toPromise()
                .then((data: any) => { this.globals.permisosDeRol = data.result; });
        } catch (error) {
            console.log(error)
        }
    }

    public isUserAuthenticated(accessToken): boolean {
        accessToken = (accessToken) ? accessToken : localStorage.getItem('token');
        return !jwtHelper.isTokenExpired(accessToken);
    }

}


