import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs/index';
import { ApiResponse } from '../../model/login/api.response';
import { map } from 'rxjs/operators';
import { Globals } from '../../model/globals.model';

@Injectable()
export class ParametrosService {

    constructor(private globals: Globals, private http: HttpClient) { }


    baseUrlBuscarInstrumentos = this.globals.dominioApiService + 'instrumentos/listadoInstrumentos/';


    listarInstrumentos(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlBuscarInstrumentos);
    }


}
