import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs/index';
import { ApiResponse } from '../../model/login/api.response';
import { map } from 'rxjs/operators';
import { Globals } from '../../model/globals.model';

@Injectable()
export class CreditosService {

  constructor(private globals: Globals, private http: HttpClient) { }

  baseUrlInsertarSolicitud = this.globals.dominioApiService + 'creditos/insertarSolicitudCredito/';
  baseUrlInsertarSolicitudSinImagen = this.globals.dominioApiService + 'creditos/insertarSolicitudCreditoRefacil/';
  baseUrllistarSolicitudes = this.globals.dominioApiService + 'creditos/listarSolicitudes/';
  baseUrlConteoSolicitudes = this.globals.dominioApiService + 'creditos/conteoSolicitudes/';
  baseUrlAceptarSolicitudes = this.globals.dominioApiService + 'creditosUsuario/aceptarSolicitudCredito/';
  baseUrlConsultarSaldo = this.globals.dominioApiService + 'creditosUsuario/consultarSaldo';
  baseUrlConsultarGanancias = this.globals.dominioApiService + 'creditosUsuario/consultarGanancias';
  baseUrlConsultarGananciasUsuario = this.globals.dominioApiService + 'creditosUsuario/consultarGananciasUsuario';
  baseUrlConsultarGananciasUsuarioSinPaginado = this.globals.dominioApiService + 'creditosUsuario/consultarGananciasUsuarioSinPaginado';
  baseUrlConsultarSaldoADmin = this.globals.dominioApiService + 'creditosUsuario/consultarSaldosAdmin/';
  baseUrlConsultarSaldoEquipo = this.globals.dominioApiService + 'creditosUsuario/consultarSaldosEquipo/';
  baseUrlActualizarEstadoCredito = this.globals.dominioApiService + 'creditos/actualizarEstadoCredito';
  baseUrlActualizarValorCredito = this.globals.dominioApiService + 'creditosUsuario/actualizarValorCredito';
  baseUrlActualizarGuardarCreditos = this.globals.dominioApiService + 'creditosUsuario/guardarCreditos';
  baseUrlConsultarHistorico = this.globals.dominioApiService + 'creditosUsuario/consultarSaldoHistorico/';

  baseUrlListarSolicitudesEstadoUsuario = this.globals.dominioApiService + 'creditosUsuario/listarSolicitudesEstadoUsuario/';
  baseUrlTransferirCreditos = this.globals.dominioApiService + 'creditosUsuario/transferirGanancias';




  crearSolicitudCreditos(beanFormulario, tipoImagen): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlInsertarSolicitud + tipoImagen, beanFormulario);
  }

  crearSolicitudCreditosSinImagen(beanFormulario): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlInsertarSolicitudSinImagen, beanFormulario);
  }
  listarSolicitudesPorEstado(estado,params): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrllistarSolicitudes + estado + '/', { params });
  }
  listarSolicitudesHistorico(usuario): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlConsultarHistorico + usuario);
  }
  conteoSolicitudesPorEstado(estado, idEquipo): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlConteoSolicitudes + estado + '/' + idEquipo);
  }
  aceptarSolicitud(beanCreditosRecargar, idSolicitud, idUsuario): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlAceptarSolicitudes +  idSolicitud + '/' + idUsuario, beanCreditosRecargar);
  }
  consultarSaldoTotal(idUsuario): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlConsultarSaldo + '/' + idUsuario);
  }
  consultarGanancias(idUsuario): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlConsultarGanancias + '/' + idUsuario);
  }
  consultarGananciasUsuario(idUsuario, params): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlConsultarGananciasUsuario + '/' + idUsuario, { params });
  }
  consultarGananciasUsuarioSinPaginado(idUsuario): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlConsultarGananciasUsuarioSinPaginado + '/' + idUsuario);
  }
  actualizarEstadoSolicitud(idSolicitud): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(this.baseUrlActualizarEstadoCredito + '/' + idSolicitud, null).pipe(map(data => data));

  }
  actualizarEstadoSolicitudObservacion(idSolicitud, observacion): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlActualizarEstadoCredito + '/' + idSolicitud, observacion);

  }

  listarCreditosEquipo(idEquipo): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlConsultarSaldoEquipo + idEquipo);
  }
  listarCreditosAdmin(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlConsultarSaldoADmin);
  }


  actualizarValorCredito(idCredito, totalCreditos, nroComprobante, idUsuario): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlActualizarValorCredito + '/' + idCredito + '/' + totalCreditos + '/' + nroComprobante + '/' + idUsuario, null).pipe(map(data => data));

  }

  guardarCreditos(beanCreditosRecargar): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlActualizarGuardarCreditos, beanCreditosRecargar);

  }

  listarSolicitudesEstadoUsuario(estado, idUsuario): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlListarSolicitudesEstadoUsuario + estado + '/' + idUsuario);
  }
  transferirDinero(idUsuario): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlTransferirCreditos + '/' + idUsuario);
  }

}
