import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs/index";
import { ApiResponse } from "../../model/login/api.response";
import { map } from "rxjs/operators";
import { Globals } from "../../model/globals.model";

@Injectable()
export class PrecioServices {
  constructor(private globals: Globals, private http: HttpClient) { }

  baseUrlPreciosIdEquipo =
    this.globals.dominioApiService + "precios/preciosPorParentId/";
  baseUrlPreciosParentId =
    this.globals.dominioApiService + "precios/preciosPorParentIdSubDist/";
  baseUrlPreciosPermisos =
    this.globals.dominioApiService + "precios/insertarPermisosPrecios/";
  baseUrlActualizarPreciosPermisos =
    this.globals.dominioApiService + "precios/actualizarPrecioFinal/";
    baseUrlActualizarPreciosAdmin =
    this.globals.dominioApiService + "precios/actualizarPrecioFinalAdmin/";
  baseUrlActualizarPreciosDist =
    this.globals.dominioApiService + "precios/actualizarPrecioDist/";

  consutarPreciosIdEquipo(idEquipo, idUsuario): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlPreciosIdEquipo + idEquipo + '/' + idUsuario);
  }

  consutarPreciosPorParentIdSubDist(parentId): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlPreciosParentId + parentId);
  }
  mapearPreciosTipoCuenta(idUsuario, idEquipo): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlPreciosPermisos + idUsuario + "/" + idEquipo
    );
  }

  actualizarPrecioCuenta(idPrecio, valor): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.baseUrlActualizarPreciosPermisos + idPrecio, valor 
    );
  }
  actualizarPrecioAdmin(idPrecio, valor): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlActualizarPreciosAdmin + idPrecio + '/' + valor 
    );
  }
  actualizarPrecioCuentaDist(idTipoCuenta, valor): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlActualizarPreciosDist + idTipoCuenta + "/" + valor
    );
  }
}
