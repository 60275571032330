import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Injectable } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutes, AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedService } from './shared.service';
import { NgbdSortableHeader } from './sortable.directive';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { NestableModule } from 'ngx-nestable';
import { NgxSpinnerModule } from "ngx-spinner";
import { LightboxModule } from 'ngx-lightbox';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';



FullCalendarModule.registerPlugins([
    dayGridPlugin,
    timeGridPlugin,
    listPlugin,
    interactionPlugin
])


import { ParametrosService } from 'src/core/ParametrosService/parametros.service';
import { VentasService } from 'src/core/VentasSercices/cuentas.service';
import { CuentasService } from 'src/core/CuentasServices/cuentas.service';
import { TicketsServices } from 'src/core/TicketsServices/categorias.service';
import { CategoriasServices } from 'src/core/CategoriasServices/categorias.service';
import { Globals } from 'src/model/globals.model';
import { DashboardService } from 'src/core/DashboardServices/dashboard.service';
import { AuthGuard } from 'src/core/guard';
import { FuncionesGlobalesService } from 'src/core/FuncionesGlobalesService/funcionesGlobalesService';
import { ApiService } from 'src/core/LoginService/api.service';
import { NotificacionesService } from 'src/core/NotificacionesService/notificaciones.service';
import { PermisosService } from 'src/core/PermisosService/permisos.service';
import { CreditosService } from 'src/core/CreditosServices/creditos.service';
import { NoticiasService } from 'src/core/NoticiasService/noticias.service';
import { PrecioServices } from 'src/core/PrecioServices/precio.service';
import { RouterModule } from '@angular/router';
import { JWT_OPTIONS, JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { TokenInterceptor } from 'src/core/LoginService/interceptor';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CommonUtil } from './utilidades/common-util';
import { PrecioCategoriaServices } from 'src/core/PrecioCategoriaServices/precioCategoria.service';
import { PaypalService } from 'src/core/PaypalService/paypalService';
export function tokenGetter() {
    return localStorage.getItem("token");
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: true,
        }),
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: ["example.com"],
                blacklistedRoutes: ["example.com/examplebadroute/"],
            },
        }),
    ],
    providers: [
        SharedService,
        ParametrosService,
        VentasService,
        CuentasService,
        TicketsServices,
        PaypalService,
        CategoriasServices,
        PrecioCategoriaServices,
        Globals,
        DashboardService,
        AuthGuard,
        FuncionesGlobalesService,
        ApiService,
        NotificacionesService,
        PermisosService,
        CreditosService,
        NoticiasService,
        PrecioServices,
        CommonUtil,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService,

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

