import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { ApiResponse } from "../../model/login/api.response";
import { map } from "rxjs/operators";
import { Globals } from "../../model/globals.model";

@Injectable()
export class TicketsServices {
  constructor(private globals: Globals, private http: HttpClient) {}

  baseUrlConsultarMotivosTickets =
    this.globals.dominioApiService + "tickets/consultarMotivos";

  baseUrlConsultarTicketsAdmin =
    this.globals.dominioApiService + "tickets/consultarTicketsAdmin/";

  baseUrlEliminarMotivoPermanente =
    this.globals.dominioApiService + "tickets/motivosCuenta/";

  baseUrlConsultarTicketsUsuario =
    this.globals.dominioApiService + "tickets/consultarTicketsUsuario/";
  baseUrlConsultarUsuariosParaTicket =
    this.globals.dominioApiService + "tickets/consultarUsuariosParaTicket/";

  baseUrlGuardarTicket =
    this.globals.dominioApiService + "tickets/insertarTicket/";
  baseUrlGuardarMotivosTicket =
    this.globals.dominioApiService + "tickets/insertarMotivosTicket/";

  baseUrlRespuestaGuardarTicket =
    this.globals.dominioApiService + "tickets/insertarRespuestaTicket/";

  baseUrlConsultarMensajesTicketPorIdTicket =
    this.globals.dominioApiService +
    "tickets/consultarMensajesTicketPorIdTicket/";

  consultarMotivosTickets(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlConsultarMotivosTickets);
  }
  consultarTicketsAdmin(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlConsultarTicketsAdmin);
  }
  consultarTicketsPorUsuario(idUsuario): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlConsultarTicketsUsuario + idUsuario
    );
  }
  consultarUsuariosParaTickets(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlConsultarUsuariosParaTicket);
  }

  guardarTicket(beanTicket): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlGuardarTicket, beanTicket);
  }
  guardarMensajeTicketRespuesta(beanTicket): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.baseUrlRespuestaGuardarTicket,
      beanTicket
    );
  }

  guardarMotivoTicket(beanMotivoTicket): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.baseUrlGuardarMotivosTicket,
      beanMotivoTicket
    );
  }
  consultarMensajesTicketPorIdTicket(idTicket): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlConsultarMensajesTicketPorIdTicket + idTicket
    );
  }

  eliminarMotivos(idMotivo): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlEliminarMotivoPermanente + idMotivo
    );
  }
}
