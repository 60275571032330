import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs/index';
import { ApiResponse } from '../../model/login/api.response';
import { map } from 'rxjs/operators';
import { Globals } from '../../model/globals.model';

@Injectable()
export class CategoriasServices {

    constructor(private globals: Globals, private http: HttpClient) { }


    baseUrlGuardarCategorias = this.globals.dominioApiService + 'categoriasCuenta/';
    baseUrlListarCategorias = this.globals.dominioApiService + 'categoriasCuenta/buscarCategoriasCuentaEstadoCategoria/';



    guardarCategoria(categorias): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.baseUrlGuardarCategorias, categorias);
    }
    actualizarCategoria(categorias): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.baseUrlGuardarCategorias, categorias);
    }
    listarCategorias(estado): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlListarCategorias + estado);
    }
    listarCategoriasAll(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlGuardarCategorias);
    }
    buscarCategoriaPorId(idCategoria): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlGuardarCategorias + idCategoria);
    }

}
