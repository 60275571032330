import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import Swal, { SweetAlertType } from 'sweetalert2';

@Injectable()
export class FuncionesGlobalesService {

    sweetAlert: any;
    codigoExito: number = 200;
    codigoError: number = 400;
    codigoWarning: number = 20;
    codigoInfo: number = 21;
    codigoQuestion: number = 22;

    constructor(private http: HttpClient) {
    }

    cerrarAlertPeticion() {
        Swal.close();
    }

    mostrarMensajeErrorConsola(response) {
    }

    limpiarCadena(cadena, tipo) {
        let nuevaCadena = cadena;
        let arrayLetras = [
            'á', 'à', 'ä', 'â', 'ª', 'Á', 'À', 'Â', 'Ä',
            'é', 'è', 'ë', 'ê', 'É', 'È', 'Ê', 'Ë',
            'í', 'ì', 'ï', 'î', 'Í', 'Ì', 'Ï', 'Î',
            'ó', 'ò', 'ö', 'ô', 'Ó', 'Ò', 'Ö', 'Ô',
            'ú', 'ù', 'ü', 'û', 'Ú', 'Ù', 'Û', 'Ü',
            'Ñ', 'ñ', 'ç', 'Ç'
        ];
        let _arrayLetras = [
            'a', 'a', 'a', 'a', 'a', 'A', 'A', 'A', 'A',
            'e', 'e', 'e', 'e', 'E', 'E', 'E', 'E',
            'i', 'i', 'i', 'i', 'I', 'I', 'I', 'I',
            'o', 'o', 'o', 'o', 'O', 'O', 'O', 'O',
            'u', 'u', 'u', 'u', 'U', 'U', 'U', 'U',
            'n', 'N', 'c', 'C'
        ];
        for (let indice in arrayLetras) {
            nuevaCadena = nuevaCadena.replace(new RegExp(arrayLetras[indice], "g"), _arrayLetras[indice]);
        }
        //Esta parte se encarga de eliminar cualquier caracter extraño
        nuevaCadena = nuevaCadena.replace(/[><¨=º~!¡?;,:°.*+¿%&?^${}()|[\]\\]/g, '');

        switch (tipo) {
            case 'nombre':
                nuevaCadena = nuevaCadena.replace(/[-+_@.#|º1234567890]/g, '');
                break;
            case 'numero':
                nuevaCadena = nuevaCadena.replace(/-|.|_|@|#/g, '');
                nuevaCadena = nuevaCadena.replace(/[^\d]/g, '');
                break;
            case 'correo':
                break;
            case 'texto':
                break;

        }
        return nuevaCadena;
    }

    mostrarMensajePeticion(tipoMensaje: number, titulo: string, mensaje: string, estadoPeticion: number, callback) {
        titulo = (!isNullOrUndefined(titulo)) ? titulo : 'Procesando Petición';
        mensaje = (!isNullOrUndefined(mensaje)) ? mensaje : 'Por favor espere...';
        tipoMensaje = (!isNullOrUndefined(tipoMensaje)) ? tipoMensaje : 1;
        callback = (!isNullOrUndefined(callback)) ? callback : () => { };
        let estado: SweetAlertType;
        switch (estadoPeticion) {
            case this.codigoExito:
                estado = 'success';
                break;
            case this.codigoError:
            case 404:
            case 409:
                estado = 'error';
                break;
            case this.codigoWarning:
                estado = 'warning';
                break;
            case this.codigoInfo:
                estado = 'info';
                break;
            case this.codigoQuestion:
                estado = 'question';
                break;
            default:
                estado = 'error';
                break;
        }

        if (tipoMensaje == 1) {
            Swal.fire(titulo, mensaje, estado).then((result) => { callback(); });
        } else if (tipoMensaje == 2) {
            Swal.fire({
                title: titulo,
                showConfirmButton: false,
                text: mensaje,
                imageUrl: './assets/img/loading-54.gif',
                imageWidth: 290,
                imageHeight: 200,
                imageAlt: 'Cargando...',
                animation: false
            });
        }

    }



    /* Funciones para Pasar Numeros a Letras */
    unidades(num) {
        switch (num) {
            case 1: return "UN";
            case 2: return "DOS";
            case 3: return "TRES";
            case 4: return "CUATRO";
            case 5: return "CINCO";
            case 6: return "SEIS";
            case 7: return "SIETE";
            case 8: return "OCHO";
            case 9: return "NUEVE";
        }
        return "";
    }//Unidades()

    decenas(num) {
        let decena = Math.floor(num / 10);
        let unidad = num - (decena * 10);
        switch (decena) {
            case 1:
                switch (unidad) {
                    case 0: return "DIEZ";
                    case 1: return "ONCE";
                    case 2: return "DOCE";
                    case 3: return "TRECE";
                    case 4: return "CATORCE";
                    case 5: return "QUINCE";
                    default: return "DIECI" + this.unidades(unidad);
                }
            case 2:
                switch (unidad) {
                    case 0: return "VEINTE";
                    default: return "VEINTI" + this.unidades(unidad);
                }
            case 3: return this.decenasY("TREINTA", unidad);
            case 4: return this.decenasY("CUARENTA", unidad);
            case 5: return this.decenasY("CINCUENTA", unidad);
            case 6: return this.decenasY("SESENTA", unidad);
            case 7: return this.decenasY("SETENTA", unidad);
            case 8: return this.decenasY("OCHENTA", unidad);
            case 9: return this.decenasY("NOVENTA", unidad);
            case 0: return this.unidades(unidad);
        }
    }//Decenas()

    decenasY(strSin, numUnidades) {
        if (numUnidades > 0) {
            return strSin + " Y " + this.unidades(numUnidades);
        }
        return strSin;
    }//DecenasY()

    centenas(num) {
        let centenas = Math.floor(num / 100);
        let decenas = num - (centenas * 100);

        switch (centenas) {
            case 1:
                if (decenas > 0)
                    return "CIENTO " + this.decenas(decenas);
                return "CIEN";
            case 2: return "DOSCIENTOS " + this.decenas(decenas);
            case 3: return "TRESCIENTOS " + this.decenas(decenas);
            case 4: return "CUATROCIENTOS " + this.decenas(decenas);
            case 5: return "QUINIENTOS " + this.decenas(decenas);
            case 6: return "SEISCIENTOS " + this.decenas(decenas);
            case 7: return "SETECIENTOS " + this.decenas(decenas);
            case 8: return "OCHOCIENTOS " + this.decenas(decenas);
            case 9: return "NOVECIENTOS " + this.decenas(decenas);
        }
        return this.decenas(decenas);
    }//Centenas()

    seccion(num, divisor, strSingular, strPlural) {
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)
        let letras = "";
        if (cientos > 0)
            if (cientos > 1)
                letras = this.centenas(cientos) + " " + strPlural;
            else
                letras = strSingular;
        if (resto > 0)
            letras += "";
        return letras;
    }//Seccion()

    miles(num) {
        let divisor = 1000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMiles = this.seccion(num, divisor, "UN MIL", "MIL");
        let strCentenas = this.centenas(resto);

        if (strMiles == "")
            return strCentenas;

        return strMiles + " " + strCentenas;
    }//Miles()

    millones(num) {
        let divisor = 1000000;
        let cientos = Math.floor(num / divisor)
        let resto = num - (cientos * divisor)

        let strMillones = this.seccion(num, divisor, "UN MILLON DE", "MILLONES DE");
        let strMiles = this.miles(resto);

        if (strMillones == "")
            return strMiles;

        return strMillones + " " + strMiles;
    }//Millones()

    numeroALetras(num, currency) {
        currency = currency || {};
        let data: any = {
            "numero": num,
            "enteros": Math.floor(num),
            "centavos": (((Math.round(num * 100)) - (Math.floor(num) * 100))),
            "letrasCentavos": "",
            "letrasMonedaPlural": 'PESOS', //"PESOS", 'Dólares', 'Bolívares', 'etcs'
            "letrasMonedaSingular": 'PESOS', //"PESO", 'Dólar', 'Bolivar', 'etc'
            "letrasMonedaCentavoPlural": "CENTAVOS",
            "letrasMonedaCentavoSingular": "CENTAVO"
        }
        let these = this;
        if (data.centavos > 0) {
            data.letrasCentavos = "CON " + (function () {
                if (data.centavos == 1)
                    return these.millones(data.centavos) + " " + data.letrasMonedaCentavoSingular;
                else
                    return these.millones(data.centavos) + " " + data.letrasMonedaCentavoPlural;
            })();
        };

        if (data.enteros == 0)
            return "CERO " + data.letrasMonedaPlural + " " + data.letrasCentavos;
        if (data.enteros == 1)
            return these.millones(data.enteros) + " " + data.letrasMonedaSingular + " " + data.letrasCentavos;
        else
            return these.millones(data.enteros) + " " + data.letrasMonedaPlural + " " + data.letrasCentavos;
    }//NumeroALetras()

}


