import { Injectable } from "@angular/core";

@Injectable()
export class Globals {
  constructor() {
    this.dominioApiService = "https://modocinex.net/apiZona/";

/*     this.dominioApiService = "https://zonav.com.co/apiZona/";
 */    this.nombreCarpetaServidor = "sigma-front";
    this.clientId = 'AUp21o7w1e2BTR7Ntv8mzFvYqltuB7ZZ-ibrBRHOo4akug5xp0Ms0vKeK9Y2RI-vWT2wNDzMHXgQAIi3'
  }

  resultFull: any;
  listaCoordenadasTitulos: any;
  dominioApiService: any;
  nombreCarpetaServidor: any;
  dominioServerProd: any;
  instanciaModal: any;
  permisosDeRol: any;
  clientId: any;
}

export class ResponseFull {
  message?: any;
  result?: any;
  status?: any;
}
