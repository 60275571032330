import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs/index';
import { ApiResponse } from '../../model/login/api.response';
import { map } from 'rxjs/operators';
import { Globals } from '../../model/globals.model';

@Injectable()
export class CuentasService {

  constructor(private globals: Globals, private http: HttpClient) { }


  baseUrlGuardarTipoCuenta = this.globals.dominioApiService + 'cuentas/insertarTipoCuenta/';
  baseUrlActualizarTipoCuenta = this.globals.dominioApiService + 'cuentas/actualizarTipoCuenta/';
  baseUrlListarTipoCuentas = this.globals.dominioApiService + 'cuentas/listarTipoCuentas/';
  baseUrlListarTipoCuentasPorId = this.globals.dominioApiService + 'cuentas/listarTipoCuentasPorId/';
  baseUrlListarTipoCuentasPorEstado = this.globals.dominioApiService + 'cuentas/listarTipoCuentasPorEstado/';

  baseUrlGuardarCuenta = this.globals.dominioApiService + 'cuentas/insertarCuenta/';
  baseUrlGuardarCuentasExcel = this.globals.dominioApiService + 'cuentas/insertarCuentasExcel/';
  baseUrlListarCuentasPorEstado = this.globals.dominioApiService + 'cuentas/listarCuentasPorEstado/';
  baseUrlListarCuentasPorId = this.globals.dominioApiService + 'cuentas/listarCuentasPorId/';
  baseUrlActualizarCuenta = this.globals.dominioApiService + 'cuentas/actualizarCuenta/';
  baseUrlEliminarCuenta = this.globals.dominioApiService + 'cuentas/eliminarCuenta/';
  baseUrlEliminarCuentaPermanente = this.globals.dominioApiService + 'cuentas/eliminarCuentaPermanente/';
  baseUrlEliminarTipoCuentaPermanente = this.globals.dominioApiService + 'cuentas/eliminarTipoCuentaPermanente/';
  baseUrlListarEquipoUsuario = this.globals.dominioApiService + 'equiposUsuarios/buscarEquiposUsuariosIdUsuario/';


  guardarTipoCuenta(TipoCuenta,tipoImagen): Observable<ApiResponse> {
   return this.http.post<ApiResponse>(this.baseUrlGuardarTipoCuenta + tipoImagen, TipoCuenta);  }
  actualizarTipoCuenta(TipoCuenta,tipoImagen): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlActualizarTipoCuenta + tipoImagen, TipoCuenta);
  }
  listarTipoCuentas(): Observable<ApiResponse> {

    return this.http.get<ApiResponse>(this.baseUrlListarTipoCuentas);
  }
  listarTipoCuentasPorId(idTipoCuenta): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlListarTipoCuentasPorId + idTipoCuenta);
  }

  guardarCuenta(Cuenta): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlGuardarCuenta, Cuenta);
  }
  guardarCuentaExcel(Cuentas): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlGuardarCuentasExcel, Cuentas);
  }
  listarTipoCuentaEstado(estado): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlListarTipoCuentasPorEstado + estado);
  }
  listarCuentasDisponibles(estado): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlListarCuentasPorEstado + estado);
  }
  listarCuentasDisponiblesPaginado(estado,email, params): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlListarCuentasPorEstado + estado +"/" + email,
      { params }
    );
  }

  listarCuentaPorId(idCuenta): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlListarCuentasPorId + idCuenta);
  }

  actualizarCuenta(Cuenta): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrlActualizarCuenta, Cuenta);
  }
  cambiarEstadoCuenta(idCuenta): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlEliminarCuenta + idCuenta);
  }
  eliminarPermanentemente(idCuenta): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlEliminarCuentaPermanente + idCuenta);
  }
  eliminarTipoCuentaPermanentemente(idTipoCuenta): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlEliminarTipoCuentaPermanente + idTipoCuenta);
  }

  listarEquipoUsuario(username): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlListarEquipoUsuario + username);
  }
}
