import { FuncionesGlobalesService } from './../../core/FuncionesGlobalesService/funcionesGlobalesService';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermisosService } from '../../core/PermisosService/permisos.service';
import { isNullOrUndefined } from 'util';

import { Globals } from '../../model/globals.model';
import { ApiService } from '../../core/LoginService/api.service';

@Injectable({providedIn: "root"})

export class AuthGuard implements CanActivate {
    constructor(private apiService: ApiService, private globals: Globals, private funcionesGlobalesService: FuncionesGlobalesService, private router: Router, private permisosService: PermisosService) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let accessToken = localStorage.getItem('token');
        let idRol = window.localStorage.getItem('idRol');
        let hasPermission = false;
        let listaRuta = (state.url).split("/");
        let rutaActual = listaRuta[1];
        let listaDeRutasSinPermiso = ['login'];
        let listaDeRutasSinPermisoAutenticado = ['settings', 'vistapdf'];

        // Si la ruta actual existe en la lista de rutas sin permisos y no esta autenticado permite acceso a la ruta sin permiso
        if (listaDeRutasSinPermiso.indexOf(rutaActual) !== -1 && !this.permisosService.isUserAuthenticated(null)) {
            hasPermission = true;
            // Si la ruta actual existe en la lista de rutas sin permisos y esta autenticado permite acceso a la ruta sin permiso de usuario autenticado
        } else if (listaDeRutasSinPermiso.indexOf(rutaActual) !== -1 && this.permisosService.isUserAuthenticated(null)) {
            hasPermission = false;
            this.router.navigate(['dashboard']);
            // Si la ruta actual no existe en la lista de rutas sin permisos y esta autenticado permite acceso a la ruta con permiso de usuario autenticado
        } else if (listaDeRutasSinPermiso.indexOf(rutaActual) === -1 && this.permisosService.isUserAuthenticated(null)) {
            if (listaDeRutasSinPermisoAutenticado.indexOf(rutaActual) !== -1) {
                hasPermission = true;
            } else {
                await this.permisosService.obtenerPermisosPorRolDeUsuario(idRol, 'A', '1');
                let permisosDeRol = this.globals.permisosDeRol;
                if (permisosDeRol && Array.isArray(permisosDeRol) && permisosDeRol.length > 0) {
                    (permisosDeRol).some((permiso) => {
                        if (permiso.urlfuncionalidad == rutaActual) {
                            hasPermission = true;
                            return true;
                        }
                    });
                }
                if (!hasPermission && rutaActual != 'dashboard') {
                    this.funcionesGlobalesService.mostrarMensajePeticion(1, 'Información', 'No tiene permiso asignado para (' + rutaActual + ') en ZONA VIRTUAL, consulta con el administrador.', 404, () => {
                        this.router.navigateByUrl('/dashboard');
                    });
                }
            }
            // De lo contrario no permite acceso y redirecciona a login
        } else {
            hasPermission = false;
            this.funcionesGlobalesService.mostrarMensajePeticion(1, 'Información', 'Su sesión  en ZONA VIRTUAL ha caducado por favor inicia sesión nuevamente.', 401, () => {
                localStorage.clear();
                this.router.navigateByUrl('/login');
            });
        }
        return hasPermission;
    }
}
