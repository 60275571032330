import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/index";

import { Globals } from "../../model/globals.model";

@Injectable()
export class PaypalService {
    constructor(private globals: Globals, private http: HttpClient) { }

    baseUrlGenerateLink =
        this.globals.dominioApiService + "pagos/pay/";
    baseUrlSuccess =
        this.globals.dominioApiService + "pagos/success";


    generateLink(valor, idUsuario): Observable<any> {
        let params = new HttpParams()
            .set('total', valor)
            .set('userId', idUsuario); // Agregado
        return this.http.get(this.baseUrlGenerateLink, { params: params, responseType: 'text' });
    }
    succesPay(paymentId, PayerId): Observable<any> {
        let params = new HttpParams()
            .set('paymentId', paymentId)
                .set('token', "TOKEN")
            .set('PayerID', PayerId); // Agregado
        return this.http.get(this.baseUrlSuccess, { params: params, responseType: 'text' });
    }

}
