export class CommonUtil {
  public getRequestParams(page: number, pageSize: number): any {
    let params: any = {};

    if (page) {
      params[`page`] = page - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }
}



export class ModelNameId {
  constructor() {
      this.id = null;
      this.name = null;
  }

  id?: any;
  name?: any;

}
