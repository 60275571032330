import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs/index";
import { ApiResponse } from "../../model/login/api.response";
import { map } from "rxjs/operators";
import { Globals } from "../../model/globals.model";

@Injectable()
export class NoticiasService {
  constructor(private globals: Globals, private http: HttpClient) {}

  baseUrlGuardarImagen =
    this.globals.dominioApiService + "noticias/insertarImagen/";
  baseUrlListarImagenesNoticia =
    this.globals.dominioApiService + "noticias/listarImagenesNoticia/";
  baseUrlActualizarEstadoNoticia =
    this.globals.dominioApiService + "noticias/actualizarEstadoCredito/";
    baseUrlConsultarImagenesEstado =
    this.globals.dominioApiService + "noticias/consultarImagenesEstado/";

  guardarImagen(ImagenNoticia, tipoImagen): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.baseUrlGuardarImagen + tipoImagen,
      ImagenNoticia
    );
  }
  actualizarEstado(idImagen, estado): Observable<ApiResponse> {
    return this.http
      .put<ApiResponse>(
        this.baseUrlActualizarEstadoNoticia + idImagen + "/" + estado,
        null
      )
      .pipe(map((data) => data));
  }
  cargarImagenes(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrlListarImagenesNoticia);
  }

  consultarImagenesEstado(estado): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.baseUrlConsultarImagenesEstado + estado
    );
  }
}
