import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs/index';
import { ApiResponse } from '../../model/login/api.response';
import { map } from 'rxjs/operators';
import { Globals } from '../../model/globals.model';

@Injectable()
export class DashboardService {

    constructor(private globals: Globals, private http: HttpClient) { }


    baseUrlEstadisticas = this.globals.dominioApiService + 'cuentas/conteoCuentasCompletas/';
    baseUrlTipoCuentas = this.globals.dominioApiService + 'cuentas/listarTipoCuentas/';
    baseUrlTransacciones = this.globals.dominioApiService + 'cuentas/listarUltimasTransacciones/';


    estadidisticasGenerales(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlEstadisticas);
    }

    consultarTipoCuentas(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlTipoCuentas);
    }


    listarTransacciones(idUsuario): Observable<ApiResponse> {
      return this.http.get<ApiResponse>(this.baseUrlTransacciones + idUsuario);
    }
}
