import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs/index';
import { ApiResponse } from '../../model/login/api.response';
import { map } from 'rxjs/operators';
import { Globals } from '../../model/globals.model';

@Injectable()
export class NotificacionesService {

    constructor(private globals: Globals, private http: HttpClient) { }

    public _responseNotificacionPersonal = new BehaviorSubject(null);
    responseNotificacionPersonal$ = this._responseNotificacionPersonal.asObservable();
    public _responseNotificacionPorEdicto = new BehaviorSubject(null);
    responseNotificacionPorEdicto$ = this._responseNotificacionPorEdicto.asObservable();
    public _responseNotificacionPorEstado = new BehaviorSubject(null);
    responseNotificacionPorEstado$ = this._responseNotificacionPorEstado.asObservable();

    baseUrlNotificacionesPorUsuario = this.globals.dominioApiService + 'alarmasUsuarios/';
    baseUrlTodasNotificacionesPorUsuario = this.globals.dominioApiService + 'alarmasUsuarios/buscarTodasLasAlarmasPorUsuarioId/';
    baseUrlBandejaNotificaciones = this.globals.dominioApiService + 'entidadNotificacion/buscarInfoBandejaNotificacion/';
    // baseUrlActualizarEstadoNotificacionDeBandeja = this.globals.dominioApiService + 'entidadNotificacion/actualizarNotificacionEntidadNotificacion/';
    baseUrlActualizarEstadoNotificacionDeBandeja = this.globals.dominioApiService + 'entidadNotificacion/selloFirmaEntidadNotificacion/';
    baseUrlBuscarDocumentoDeNotificacion = this.globals.dominioApiService + 'documentosRadicacion/buscarRadicado/';
    baseUrlBuscarDocumentoDeCitacion = this.globals.dominioApiService + 'entidadNotificacion/buscarImagenCitacion/';
    baseUrlBuscarPaquetedNotificacionTramite = this.globals.dominioApiService + 'entidadNotificacion/buscarPaquetedNotificacionTramite/';
    baseUrlImprimirBandejaEstado = this.globals.dominioApiService + 'entidadNotificacion/imprimirBandejaNotificacionEstado/';
    baseUrlBuscarHistoricoPaquetesdNotificacionTramite = this.globals.dominioApiService + 'entidadNotificacion/buscarHistoricoImpresiones/';
    baseUrBuscarHistoricoPorPaquete = this.globals.dominioApiService + 'entidadNotificacion/buscarHistoricoPorPaquete/';
    baseUrBuscarHistoricoPorPaqueteEdicto = this.globals.dominioApiService + 'entidadNotificacion/buscarHistoricoEdictosPorPaquete/';

    baseUrlBandejaNotificacionesPorEdicto = this.globals.dominioApiService + 'entidadNotificacion/buscarInfoBandejaNotificacionEdicto/';
    baseUrlBandejaNotificacionesPorEstado = this.globals.dominioApiService + 'entidadNotificacion/buscarInfoBandejaNotificacionEstado/';
    baseUrlActualizarGuiaRadicado = this.globals.dominioApiService + 'entidadNotificacion/actualizarGuiaRadicado/';
    baseUrlBandejaNotificacionesCambiarEstado = this.globals.dominioApiService + 'entidadNotificacion/actualizarEstado/';
    baseUrlBuscarGestionCorrespondencia = this.globals.dominioApiService + 'gestionCorrespondencia/buscarGestionCorrespondencia/';
    baseUrlActualizarTextoActoAdministrativo = this.globals.dominioApiService + 'documentosRadicacion/actualizarTextoActoAdministrativo/';
    baseUrlActualizarEstadoFijado = this.globals.dominioApiService + 'entidadNotificacion/actualizarEstadoFijado/';
    baseUrlActualizarEstadoDesfijado = this.globals.dominioApiService + 'entidadNotificacion/actualizarEstadoDesFijado/';
    baseUrlActualizarEstadoNotificadoEdictos = this.globals.dominioApiService + 'entidadNotificacion/actualizarEstadoNotificadoEdictos/';
    baseUrlBuscarPaqueteNotificacionEdictos = this.globals.dominioApiService + 'entidadNotificacion/buscarPaqueteNotificacionEdictos/';
    baseUrlactualizarEstadoNotificacionPersonal = this.globals.dominioApiService + 'entidadNotificacion/actualizarEstadoNotificacion/';
    baseUrBuscarHistoricoPorPaqueteEstado = this.globals.dominioApiService + 'entidadNotificacion/buscarHistoricoEstadoPorPaquete/';
    baseUrBuscarTipoEntidad = this.globals.dominioApiService + 'tipoEntidad/buscarTipoEntidadParaSellos/';
    baseUrBuscarRepresentanteIdEntidad = this.globals.dominioApiService + 'entidadRepresentante/buscarRepresentanteIdEntidad/';
    baseUrlActualizarTerminosEjecutoria = this.globals.dominioApiService + 'entidadNotificacion/actualizarTerminosEjecutoria/';

    buscarRepresentanteIdEntidad(identidad: any, tipoentidad: any): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrBuscarRepresentanteIdEntidad + identidad + '/' + tipoentidad);
    }

    buscarTipoEntidad(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrBuscarTipoEntidad);
    }

    actualizarEstadoNotificacionPersonal(notificacion: Array<any>): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.baseUrlactualizarEstadoNotificacionPersonal, notificacion).pipe(map(data => data));
    }

    renunciarATerminoDeEjecutoria(idEntidadNod, renunciaATerminoDeEjecutoria): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlActualizarTerminosEjecutoria + idEntidadNod + '/' + renunciaATerminoDeEjecutoria);
    }

    actualizarEstadoNotificadoEdictos(notificacion: Array<any>): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.baseUrlActualizarEstadoNotificadoEdictos, notificacion).pipe(map(data => data));
    }

    actualizarEstadoDesfijado(notificacion: Array<any>): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.baseUrlActualizarEstadoDesfijado, notificacion).pipe(map(data => data));
    }

    actualizarEstadoFijado(notificacion: Array<any>): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.baseUrlActualizarEstadoFijado, notificacion).pipe(map(data => data));
    }

    actualizarTextoActoAdministrativo(notificacion: Array<any>): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.baseUrlActualizarTextoActoAdministrativo, notificacion).pipe(map(data => data));
    }

    BuscarHistoricoPorPaquete(paqueteHistorico): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrBuscarHistoricoPorPaquete + paqueteHistorico);
    }

    BuscarHistoricoPorPaqueteEdicto(paqueteHistorico): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrBuscarHistoricoPorPaqueteEdicto + paqueteHistorico);
    }

    BuscarHistoricoPorPaqueteEstado(paqueteHistorico): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrBuscarHistoricoPorPaqueteEstado + paqueteHistorico);
    }

    buscarGestionCorrespondencia(fechaInicio, fechaFin): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlBuscarGestionCorrespondencia + fechaInicio + '/' + fechaFin);
    }

    buscarHistoricoPaquetesdNotificacionTramite(tipoPeticion: string): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlBuscarHistoricoPaquetesdNotificacionTramite + tipoPeticion);
    }

    buscarPaquetedNotificacionTramite(listaNotificaciones: Array<any>): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.baseUrlBuscarPaquetedNotificacionTramite, listaNotificaciones).pipe(map(data => data));
    }

    imprimirBandejaNotificacionEstado(listaNotificaciones: Array<any>): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.baseUrlImprimirBandejaEstado, listaNotificaciones).pipe(map(data => data));
    }

    consultarDocumentoBandejaNotificaciones(idTramite, idTipo): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlBuscarDocumentoDeNotificacion + idTramite + '/' + idTipo);
    }
    cambiarEstadoInactivarActivar(estado, idnotificacionEnt): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlBandejaNotificacionesCambiarEstado + estado + '/' + idnotificacionEnt);
    }

    consultarDocumentoCitacionBandejaNotificaciones(radicado): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlBuscarDocumentoDeCitacion + radicado);
    }

    consultarBandejaNotificaciones(idUsuario): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlBandejaNotificaciones + idUsuario);
    }

    actualizarEstadoNotificacionDeBandeja(notificacion): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.baseUrlActualizarEstadoNotificacionDeBandeja, notificacion).pipe(map(data => data));
    }

    consultarListaNotificacionesPorUsuario(idUsuario: string): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlNotificacionesPorUsuario + idUsuario);
    }

    consultarListaTodasLasNotificacionesPorUsuario(idUsuario: string): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlTodasNotificacionesPorUsuario + idUsuario);
    }

    actualizarFechaVisualizacion(idAlarmaUsuario): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(this.baseUrlNotificacionesPorUsuario + idAlarmaUsuario, null).pipe(map(data => data));
    }


    consultarBandejaNotificacionesPorEdicto(idUsuario): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlBandejaNotificacionesPorEdicto + idUsuario);
    }
    consultarBandejaNotificacionesPorEstado(idUsuario): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlBandejaNotificacionesPorEstado + idUsuario);
    }

    guardarPropuestasRadicacion(): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrlBandejaNotificacionesPorEstado);
    }
    actualizarGuiaRadicado(beanGuiaRadicado): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.baseUrlActualizarGuiaRadicado, beanGuiaRadicado);
    }
    buscarHistoricoPorPaqueteEdicto(paqueteHistorico): Observable<ApiResponse> {
        return this.http.get<ApiResponse>(this.baseUrBuscarHistoricoPorPaqueteEdicto + paqueteHistorico);
    }
    buscarPaqueteNotificacionEdictos(listaNotificaciones: Array<any>): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.baseUrlBuscarPaqueteNotificacionEdictos, listaNotificaciones).pipe(map(data => data));
    }

    setNotificacionPersonal(notificacionPersonal) {
        this._responseNotificacionPersonal.next(notificacionPersonal);
    }

    setNotificacionPorEdicto(notificacionPorEdicto) {
        this._responseNotificacionPorEdicto.next(notificacionPorEdicto);
    }

    setNotificacionPorEstado(notificacionPorEstado) {
        this._responseNotificacionPorEstado.next(notificacionPorEstado);
    }
}
